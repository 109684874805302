import { authorizedFetch } from '../helpers/authorizedFetch';
import { convertToQueryParams } from '../helpers/params';
import { trans } from '../helpers/trans';
import isResourceCollectionDocument from '../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../japi/types/Document';
import { FetchResult, FetchResultType } from '../models/FetchResult';
import { KvkSearchResult, KvkSearchResultResource, transformToKvkSearchResult } from '../models/KvkSearchResult';

export const getKvkSearchResultsApiCall = async (companyName: string, city: string): Promise<FetchResult<KvkSearchResult[], string>> => {
    try {
        const queryParams = convertToQueryParams({
            'filter[plaats]': city,
            'filter[handelsnaam]': companyName,
        });

        const response: Response = await authorizedFetch(`${process.env.REACT_APP_API_V2_URL}/api/kvk-zoeken?${queryParams}`);

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<KvkSearchResultResource>(doc)) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const kvkSearchResults = doc.data.map(transformToKvkSearchResult);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: kvkSearchResults,
        };
    } catch (error) {
        console.error('[getKvkSearchResultsApiCall]', error);
        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
